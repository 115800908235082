<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>

          <CCardHeader>
            <br>
            <h5>Lista Case Funerarie

              <CButton
                  v-on:click="()=> { this.$router.push({name: 'casaFuneraria' }); }"
                  class="button-create-casa-funeraria"
                  size="md"
                  color="info"
              >
                <CIcon name="cil-plus"/>
                Aggiungi Casa Funeraria
              </CButton>
            </h5>


          </CCardHeader>

          <CCardBody>

            <CDataTable
                :items="items"
                :fields="fields"
                pagination
            >

              <template #bottoneVisualizzazione="{item}">
                <td>
                  <CButton
                      color="info"
                      class="m-1"
                      v-on:click="
                      ()=> {
                        $router.push(
                          {name: 'casaFuneraria',
                          params: {id: item.id}
                          });
                      }"
                  >
                    Modifica
                  </CButton>

                  <CButton
                      color="danger"
                      class="m-1"
                      v-on:click="
                      ()=> { deleteCasaFuneraria(item) }"
                  >
                    Elimina
                  </CButton>

                </td>

              </template>

            </CDataTable>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      fields: [
        'nome',
        {key: 'bottoneVisualizzazione', label: 'Operazioni'},
      ],
    };
  },

  computed: {
    ...mapGetters('casafuneraria', {
      items: 'items',
    }),

  },
  methods: {

    ...mapActions(
        "casafuneraria",
        {
          listCaseFunerarie: "list",
          deleteCasaFuneraria: 'destroy',
        }),

  },
  created() {
    this.listCaseFunerarie();
  },
  name: "ListaTotem"
}
</script>

<style scoped>
button.btn.button-create-casa-funeraria.btn-info {
  position: absolute;
  right: 0;
  margin-right: 24px;
  margin-top: -10px;
}
</style>
